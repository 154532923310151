import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import HowItWorks from "../components/howitworks";
import SkillSet from "../components/skillset";
import WhyUs from "../components/why";
import CTA from "../components/cta";

const DigitalMarketing = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: imageSharp(fluid: { originalName: { eq: "marketing-bg.jpg" } }) {
        fluid(quality: 90, maxWidth: 1920) {
          presentationWidth
          presentationHeight
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      mobile: imageSharp(fluid: { originalName: { eq: "marketers.jpg" } }) {
        fluid(maxWidth: 270, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      web: imageSharp(fluid: { originalName: { eq: "video-marketers.jpg" } }) {
        fluid(maxWidth: 270, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      backend: imageSharp(
        fluid: { originalName: { eq: "creative-content.jpg" } }
      ) {
        fluid(maxWidth: 270, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      others: imageSharp(
        fluid: { originalName: { eq: "marketing-others.jpg" } }
      ) {
        fluid(maxWidth: 270, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  `);

  const heroTitle = (
    <>
      Hire <em className="font-weight-light">Good</em> Digital Marketers
    </>
  );

  const skills = {
    title: "Finding the right skillset",
    sets: [
      {
        title: "Digital Marketers",
        image: data.mobile.fluid,
        list: [
          "Devising and executing campaigns in Adwords, Paid Social, and Email Marketing for Growth"
        ]
      },
      {
        title: "Video Marketers",
        image: data.web.fluid,
        list: [
          "FShoot and edit videos to your videography concepts, capturing attention"
        ]
      },
      {
        title: "Creative Content Writers",
        image: data.backend.fluid,
        list: [
          "Creative writing, producing content for marketing platforms, such as website, social media and news letters"
        ]
      },
      {
        title: "Others",
        image: data.others.fluid,
        list: [
          "SEO",
          "SEM",
          "Display",
          "Affiliate",
          "Programmatic advertising",
          "Video",
          "Social Media",
          "Email Marketing"
        ]
      }
    ]
  };

  const whyUs = {
    title: (
      <>
        <span className="font-weight-normal">Why</span> Choose Us
      </>
    ),
    sets: [
      {
        title: "Dedicated digital marketers",
        desc:
          "Every digital marketers on Tech2Reach is a full-time worker. " +
          "This means they have proven abilities, their time is well-managed, " +
          "and they are dedicated to your company – and your company only."
      },
      {
        title: "Unique talent pool",
        desc:
          "By allowing you to interview and select talent directly, " +
          "Tech2Reach ensures you talented digital marketers are not freelancers."
      },
      {
        title: "Thorough vetting process",
        desc:
          "Every talent on Tech2Reach is handpicked for quality and talent. " +
          "Forget choosing between freelancers – Tech2Reach shows you " +
          "high-quality, verified talent, every time."
      },
      {
        title: "Scale at will",
        desc:
          "Tech2Reach’s model is fine-tuned for quickly growing businesses " +
          "who need longer-term dedicated tech solutions. Leverage the HR and " +
          "management expertise of managing digital marketers and save yourself " +
          "time. Add or subtract digital marketers to your team quickly."
      }
    ]
  };

  return (
    <Layout>
      <SEO
        title="Digital Marketing"
        pathname="/digital-marketing/"
        image={data.hero.fluid}
        keywords={[
          "singapore",
          "hire",
          "foreign",
          "talent",
          "offshore",
          "outsource",
          "marketing",
          "digital",
          "marketers"
        ]}
      />
      <Hero
        background={data.hero.fluid}
        title={heroTitle}
        subTitle="Getting talents couldn't be any easier than this"
        buttonText="Get In Touch"
      />
      <HowItWorks
        title1="No hiring limits"
        desc1="Engage as many overseas talents as you want. Building an offshore digital marketing team at a much lower cost."
        title2="Cost savings"
        desc2="Up to 40% cost saving using offshore digital marketers as compared to hiring a local"
        title3="Dedicated talents"
        desc3="A staff fully dedicated to you. Not a freelancer. Doesn't handle other company's projects."
        title4="Don't sweat it"
        desc4="We manage and comply with the local regulations. Payroll, taxation, legal compliance, office space, welfare and all other human resource aspects are all taken care of."
      />
      <SkillSet skills={skills} />
      <WhyUs why={whyUs} />
      <CTA />
    </Layout>
  );
};

export default DigitalMarketing;
